import React from 'react'
import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group'
import styled from 'styled-components'

const timeout = 300

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props

    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
          onExit={node => {
            node.style.position = 'absolute'
            node.style.top = -1 * window.scrollY + 'px'
            node.style.width = '100%'
          }}
        >
          {status => (
            <TransitionContainer status={status}>
              {children}
            </TransitionContainer>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

const TransitionContainer = styled.div`
  ${props =>
    props.status === 'entering'
      ? `
      position: absolute; opacity: 0;
      #Nav {
        transition: all ${timeout}ms ease-in-out;
        transform: translate3d(0,-100px,0)
      }
      #Writing {
        transition: all ${timeout * 2}ms cubic-bezier(.28,.64,.23,1);
        transform: translate3d(0,100px,0)
      }
    `
      : null}
  ${props =>
    props.status === 'entered'
      ? `
      transition: all ${timeout * 2}ms ease-in-out; opacity: 1;
      #Nav {
        transition: all ${timeout}ms ease-in-out;
        transform: translate3d(0,0,0)
      }
      #Writing {
        transition: all ${timeout * 2}ms cubic-bezier(.28,.64,.23,1);
        transform: translate3d(0,0,0)
      }
    `
      : null}
  ${props =>
    props.status === 'exiting'
      ? `
      transition: all ${timeout * 2}ms ease-in-out; opacity: 0;
      #Nav {
        transition: all ${timeout}ms ease-in-out;
        transform: translate3d(0,-100px,0)
      }
      #Writing {
        transition: all ${timeout}ms ease-in-out;
        transform: translate3d(0,0,0)
      }

    `
      : null}
`

export default Transition
