// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/karldanninger/dev/karldanninger-gatsby/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("/Users/karldanninger/dev/karldanninger-gatsby/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-index-js": () => import("/Users/karldanninger/dev/karldanninger-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/Users/karldanninger/dev/karldanninger-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/karldanninger/dev/karldanninger-gatsby/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-sprint-js": () => import("/Users/karldanninger/dev/karldanninger-gatsby/src/pages/sprint.js" /* webpackChunkName: "component---src-pages-sprint-js" */),
  "component---src-pages-writing-js": () => import("/Users/karldanninger/dev/karldanninger-gatsby/src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

