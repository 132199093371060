import React from 'react'
import Transition from '../components/transition'
import styled, { createGlobalStyle, keyframes } from 'styled-components'

// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }

//   to {
//     opacity: 1;
//   }
// `
// animation: ${fadeIn} 150ms ease-in-out;
// opacity: 1;

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono:700');
  @import url('https://rsms.me/inter/inter.css');
  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
  body {
    transition: all 600ms ease-in-out;
    background-color: rgb(27,27,27);
  }
`

const TemplateWrapper = ({ children, location }) => (
  <TransitionContainer>
    <GlobalStyle />
    <Transition location={location}>{children}</Transition>
  </TransitionContainer>
)

const TransitionContainer = styled.div`
  height: 100%;
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
`

export default TemplateWrapper
